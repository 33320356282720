.project {
  background-color: #fafafa;
  height: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  display: flex;
  justify-content: center;
}
.getMore {
  margin-top: 30px;
  text-align: center;
  color: #1890ff;
  cursor: pointer;
}
.noMore {
  margin-top: 30px;
  text-align: center;
  color: #8f8f8f;
}
