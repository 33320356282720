.fieldShowCard {
  margin-bottom: 24px;
}
.showFieldValue {
  width: 100%;
}
.showFieldValue .showitem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.showFieldValue .detail-label {
  min-width: 75px;
}
.showFieldValue .detail-content {
  color: #8a8a8a;
}
.showFieldValue .examine1 {
  color: #268cff;
}
.showFieldValue .examine2 {
  color: #ff2626;
}
