.item {
  cursor: pointer;
}
.itemChoosed {
  cursor: pointer;
  background-color: #f6faff;
}
.item_detail {
  padding: 22px 21px 16px 16px;
}
.item_dot {
  width: 8px;
  height: 8px;
  background: #268cff;
  border-radius: 50%;
  margin-top: 7px;
  margin-right: 15px;
}
.item_dot2 {
  width: 8px;
  height: 8px;
  background: gray;
  border-radius: 50%;
  margin-top: 7px;
  margin-right: 15px;
}
.item_name {
  margin-bottom: 10px;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item_info {
  color: #8a8a8a;
}
.item_line {
  background-color: #e9e9e9;
  height: 2px;
}
.item_message {
  padding: 22px 21px 16px 16px;
  display: flex;
  justify-content: space-between;
}
.item_info_message {
  color: #8a8a8a;
  margin-left: 38px;
  margin-bottom: 14px;
}
.item_message_name {
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item_message_time {
  float: right;
}
.item_message_info1 {
  color: #268cff;
}
.item_message_info2 {
  color: #ff2626;
}
