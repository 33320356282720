.basicLayout .logo {
  height: 84px;
  line-height: 84px;
  color: #fff;
  font-size: 18px;
  margin: 0 24px 16px 30px;
}
.basicLayout .site-layout .site-layout-background {
  background: #fff;
}
.basicLayout .header {
  background-color: #268bff;
  height: 84px;
  line-height: 84px;
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}
.basicLayout .init {
  color: #ffffff;
  padding: 20px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}
.corner {
  margin-top: 37px;
  margin-left: 3px;
  position: absolute;
  width: 0px;
  /*  宽高设置为0，很重要，否则达不到效果 */
  height: 0px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 10px solid #ffffff;
}
.showMenu {
  visibility: visible;
  z-index: 5;
  width: 98px;
  height: 122px;
  position: absolute;
  left: 0px;
  top: 65px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 3px 7px 0px rgba(16, 52, 92, 0.4);
}
.hidden {
  display: none;
}
.systeminit {
  color: black;
  line-height: 18px;
  cursor: pointer;
  padding: 15px 5px 0px 5px;
}
