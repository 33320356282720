.system_wraper {
  background-color: #fafafa;
  padding-top: 23px;
  width: 100%;
  padding-bottom: 23px;
}
.info_sys {
  margin: 0 auto;
  width: 80%;
}
.sys_one {
  margin-right: 10px;
  margin-top: 3px;
  width: 9px;
  height: 14px;
  background: #268cff;
}
.sys_title {
  color: #268cff;
}
.sys_card {
  background-color: white;
  height: 100%;
  box-shadow: 0px 3px 10px 0px rgba(21, 94, 176, 0.12);
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
}
.sys_cardTitle {
  margin-bottom: 20px;
}
.sys_cardLine {
  height: 2px;
  background-color: #268cff;
}
.sysCardHeard {
  padding-left: 20px;
  display: flex;
  margin-bottom: 30px;
}
.sysCardHeard .basicSituation {
  font-size: 16px;
  font-weight: bold;
}
.sysCardHeards {
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
}
.sysCardHeards .basicSituation {
  font-size: 16px;
  font-weight: bold;
}
.passwordForm {
  width: 40%;
  margin-left: 30%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.content_version {
  margin-left: 80px;
}
.content_version .time {
  font-weight: bold;
}
.content_version .content {
  font-weight: bold;
  margin-top: 20px;
}
.content_version .version {
  font-weight: normal;
}
