.wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url(../../assets/bc.png);
}
.loginLeft {
  width: 1008px;
  height: 570px;
  position: relative;
  background-position: center center;
}
.loginCard {
  position: absolute;
  left: 52%;
  width: 504px;
  height: 500px;
  margin: 0 10px;
}
.loginCard .loginForm {
  width: 100%;
  height: 50vh;
  position: absolute;
  left: 0;
  top: 0;
}
.loginForms {
  width: 80%;
  text-align: center;
  margin-top: 33%;
}
.loginForms .title {
  display: flex;
  justify-content: space-between;
  margin-top: 25%;
}
.loginForms .toLogin {
  margin-top: 10%;
}
.registerForms {
  width: 80%;
  text-align: center;
  margin-top: 50px;
}
.registerForms .toLogin {
  margin-top: 3%;
}
