.case {
  background-color: #fafafa;
  height: 90vh;
  padding-top: 23px;
  padding-bottom: 23px;
  display: flex;
  justify-content: center;
}
.leftList {
  height: 100%;
}
.rightInfo {
  margin-left: 20px;
  width: 1000px;
}
.rightCard {
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(21, 94, 176, 0.12);
}
.rightBottom {
  margin-top: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(21, 94, 176, 0.12);
}
.addService {
  margin: 20px 10px;
}
.addserviceListModals {
  height: 440px;
  overflow-y: scroll;
}
.addserviceListModals .addserviceListModal {
  width: 30%;
  height: 85px;
  padding: 15px;
  background-color: #FAF7FA;
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 3px;
}
.addserviceListModals .addserviceList_content {
  display: flex;
  position: relative;
}
.addserviceListModals .addserviceList_content .serviceName {
  color: #000;
}
.addserviceListModals .addserviceList_content .serviceType {
  color: #C0BEC1;
  margin-top: 5px;
}
.addserviceListModals .addserviceList_content .serviceCheckbox {
  position: absolute;
  top: 15px;
  right: 10px;
}
.addserviceList_button {
  text-align: center;
  margin-top: 20px;
}
.addserviceList_button .button {
  margin-left: 20px;
}
.ShowFieldValueModal {
  margin-top: 20px;
  text-align: center;
}
