.listCard {
  height: 100%;
  overflow-y: auto;
  width: 400px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(21, 94, 176, 0.12);
}
.listCard_title {
  background-color: #f6faff;
  display: flex;
  justify-content: space-between;
  padding: 12px 17px 14px 14px;
}
.listCard_line {
  background-color: #268cff;
  height: 2px;
  width: 100%;
}
.listCard_extra {
  color: #268cff;
  cursor: pointer;
}
.listCardMessage {
  width: 400px;
  padding: 10px;
  background-color: #ffffff;
}
.getMore {
  width: 80%;
  text-align: center;
  margin-top: 40px;
  margin-left: 10%;
  cursor: pointer;
}
