.message {
  background-color: #fafafa;
  height: 90vh;
  padding-top: 23px;
  padding-bottom: 23px;
  display: flex;
  justify-content: center;
}
.messageleftList {
  height: 100%;
  overflow-y: auto;
}
.thirdCard {
  height: 50%;
  margin-top: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(21, 94, 176, 0.12);
}
.messageInfo {
  padding-top: 25px;
  margin-left: 25px;
}
.message_word {
  margin-top: 15px;
  color: #8a8a8a;
}
.rightInfo {
  margin-left: 20px;
  width: 1000px;
}
